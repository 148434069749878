<template>
  <div class="page">
    <bm-cardTabList
      :list="[{ name: '编辑资料' }, { name: '修改密码', path: '/account/profile/password' }]"
      current="编辑资料"
    ></bm-cardTabList>
    <el-card header="编辑资料" style="width:800px">
      <el-form label-width="7em">
        <el-form-item label="ID">
          <el-input style="width:200px" v-model="form.id" disabled></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input style="width:200px" v-model.trim="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="头像">
          <template v-if="form.avatarUrl">
            <img class="userAvatar" :src="form.avatarUrl | thumbSmall" @click="handleUploadAvatar" />
          </template>
          <template v-else>
            <div class="uploadIcon" @click="handleUploadAvatar">
              <i class="el-icon-plus"></i>
            </div>
          </template>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" style="width:200px" @click="handleSubmit">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  data: () => ({
    form: {
      id: '',
      avatarUrl: '',
      realName: ''
    }
  }),
  computed: {
    ...mapGetters({ userInfo: 'user/userInfo' })
  },
  watch: {},
  methods: {
    ...mapActions({ pickImageList: 'upload/pickImageList' }),
    handleUploadAvatar() {
      this.pickImageList({ maxCount: 1, type: 'user' })
        .then(res => {
          this.form.avatarUrl = res[0]
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    handleSubmit() {
      this.$http
        .put(`/boom-center-admin-service/sysAdmin/sysUser`, this.form)
        .then(res => {
          this.$message.success('修改资料成功')
          this.$router.push('/')
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    }
  },
  created() {},
  mounted() {
    this.form.avatarUrl = this.userInfo.avatar
    this.form.id = this.userInfo.id
    this.form.realName = this.userInfo.name
  }
}
</script>

<style lang="less" scoped>
.userAvatar {
  width: 80px;
  height: 80px;
}
.uploadIcon {
  width: 80px;
  height: 80px;
  border: 1px dashed @color-border;
  .flexCenter;
  font-size: 26px;
  cursor: pointer;
  &:hover {
    border: 1px dashed @color-primary-select;
  }
}
</style>
